import React, { useEffect, useState } from 'react';
import { Button, Grid, CircularProgress, Pagination } from '@mui/material';
import DeleteDialog from './delete_dialog.tsx';
import AddEntryDialog from './add_dialog.tsx';
import { deleteObstractProfile, fetchObstractProfiles, Profile } from '../../../services/obstract.ts';
import ProfileCard from './profile-card.tsx';

const ObstractProfile: React.FC = () => {
  const [data, setData] = useState<Profile[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [page, setPage] = useState<number>(1); // Current page
  const [pageSize] = useState<number>(10); // Profiles per page
  const [totalPages, setTotalPages] = useState<number>(1); // Total number of pages
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [selectedEntry, setSelectedEntry] = useState<Profile | null>();

  const loadData = async (pageNumber: number) => {
    setLoading(true);
    const res = await fetchObstractProfiles(pageNumber); // Pass page number and size
    setData(res.data.profiles);
    setTotalPages(Math.ceil(res.data.total_results_count / res.data.page_size)); // Calculate total pages
    setLoading(false);
  };

  // Load data on initial render and when the page changes
  useEffect(() => { loadData(page); }, [page]);

  const handleAddEntry = () => {
    loadData(page);
    setOpenAddDialog(false);
  };

  const handleEdit = (profile: Profile) => {
    setSelectedEntry(profile);
    setOpenAddDialog(true);
  };

  const handleDelete = (id: string) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    if (deleteId !== null) {
      await deleteObstractProfile(deleteId);
      await loadData(page);
      setOpenDeleteDialog(false);
      setDeleteId(null);
    }
  };

  const initAddModal = () => {
    setSelectedEntry(null);
    setOpenAddDialog(true);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value); // Set new page number
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={initAddModal}>
        Add Profile
      </Button>

      {/* Show loading spinner while data is being fetched */}
      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          {data.map((item) => (
            <ProfileCard key={item.id} profile={item} onDelete={handleDelete} onEdit={handleEdit} />
          ))}
        </Grid>
      )}

      {/* Pagination controls */}
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />

      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={confirmDelete}
      />

      <AddEntryDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        onAddEntry={handleAddEntry}
        entryData={selectedEntry}
      />
    </div>
  );
};

export default ObstractProfile;
