// Teams.tsx
import React, { useEffect, useState } from "react";
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Box } from "@mui/material";
import TeamManagement from "./team-management.tsx";
import { Link } from "react-router-dom";
import { Api } from "../../services/api.ts";

interface Team {
  id: number;
  name: string;
  slug: string;
  is_admin: string;
}

function TeamList() {
  const [teams, setTeams] = useState<Team[]>([]);
  const [loading, setLoading] = useState(true);
  const [openTeamManagement, setOpenTeamManagement] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);

  async function loadTeams() {
    try {
      const res = await Api.fetchTeams();
      setTeams(res.data.results.filter(team => !team.is_private));
    } catch (error) {
      console.error("Failed to fetch teams:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadTeams();
  }, []);

  const handleOpenTeamManagement = (team: Team | null = null) => {
    setSelectedTeam(team);
    setOpenTeamManagement(true);
  };

  const handleCloseTeamManagement = () => {
    setOpenTeamManagement(false);
    setSelectedTeam(null);
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Teams
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpenTeamManagement()}>
          Add New Team
        </Button>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <CircularProgress />
                    <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                      Loading teams...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : teams.length > 0 ? (
                teams.map((team, index) => (
                  <TableRow key={team.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{team.name}</TableCell>
                    {team.is_admin ? (
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenTeamManagement(team)}
                        >
                          Edit
                        </Button>
                        <Link to={`/dashboard/team/${team.id}`}>
                          <Button variant="contained" color="secondary" sx={{ ml: 1 }}>
                            Manage
                          </Button>
                        </Link>
                      </TableCell>
                    ) : (<></>)}

                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No teams available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TeamManagement
          open={openTeamManagement}
          onClose={handleCloseTeamManagement}
          team={selectedTeam}
          onTeamUpdated={loadTeams}
        />
      </Box>
    </Container>
  );
}

export default TeamList;
