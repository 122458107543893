import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Modal, Box, List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type Profile = {
  id: string;
  created: string;
  name: string;
  extractions: string[];
  whitelists: string[];
  aliases: string[];
  relationship_mode: string;
  extract_text_from_image: boolean;
};

interface ProfileCardProps {
  profile: Profile;
  onEdit: (profile: Profile) => void; // Function to handle edit
  onDelete: (id: string) => void; // Function to handle delete
}

const ProfileCard: React.FC<ProfileCardProps> = ({ profile, onEdit, onDelete }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleEditClick = () => {
    onEdit(profile);
  };

  const handleDeleteClick = () => {
    onDelete(profile.id);
  };

  const handleConfirmDelete = () => {
    onDelete(profile.id);
    setOpenConfirmModal(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmModal(false); // Close confirmation modal
  };

  return (
    <>
      <Card variant="outlined" style={{ margin: '16px', padding: '16px', width: '30' }}>
        <CardContent>
          <Typography variant="h5">{profile.name}</Typography>
          <Typography variant="body2">Relationship Mode: {profile.relationship_mode}</Typography>
          <Typography variant="body2">
            Extract Text from Image: {profile.extract_text_from_image ? 'Yes' : 'No'}
          </Typography>

          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalOpen}
              style={{ marginRight: '8px' }}
            >
              Details
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteClick}
              style={{ marginRight: '8px' }}
            >
              Delete
            </Button>
            {/* <IconButton color="error" onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton> */}
          </Box>
        </CardContent>
      </Card>

      {/* Modal for showing profile details */}
      <Modal open={openModal} onClose={handleModalClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Profile Details
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Name" secondary={profile.name} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Created" secondary={new Date(profile.created).toLocaleString()} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Relationship Mode" secondary={profile.relationship_mode} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Extract Text from Image" secondary={profile.extract_text_from_image ? 'Yes' : 'No'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Extractions" secondary={profile.extractions.join(', ')} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Whitelists" secondary={profile.whitelists.join(', ')} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Aliases" secondary={profile.aliases.join(', ')} />
            </ListItem>
          </List>
          <Button variant="contained" onClick={handleModalClose} style={{ marginTop: '16px' }}>
            Close
          </Button>
        </Box>
      </Modal>

      {/* Confirmation Modal for Deletion */}
      <Modal open={openConfirmModal} onClose={handleCancelDelete} aria-labelledby="confirm-delete-title">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="confirm-delete-title" variant="h6" component="h2">
            Confirm Deletion
          </Typography>
          <Typography variant="body1" style={{ marginTop: '16px' }}>
            Are you sure you want to delete this profile: {profile.name}?
          </Typography>
          <Box mt={2}>
            <Button variant="contained" color="error" onClick={handleConfirmDelete}>
              Delete
            </Button>
            <Button variant="contained" onClick={handleCancelDelete} style={{ marginLeft: '8px' }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileCard;
