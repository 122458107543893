
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Dialog, DialogTitle, DialogActions, Button, CircularProgress } from '@mui/material'; // Install @mui/material if you haven't already

import { useNavigate } from "react-router-dom";
import { setApiKey, setAuthToken, setUserData } from "../../services/storage.ts";
import InvitationsDialog from "./invites-dialog.tsx";
import { Api } from "../../services/api.ts";

const LoginRedirect = () => {
  const { user, getAccessTokenSilently, isAuthenticated, isLoading, logout } = useAuth0();
  const [loading, setLoading] = useState(true)
  const [showInvitationDialog, setShowInvitationDialog] = useState(false)
  const navigate = useNavigate();

  async function authApi(access_token: string) {
    const key = await Api.authApi(access_token)
    setApiKey(key);
  }

  async function updateAccessToken() {
    const token = await getAccessTokenSilently()
    await authApi(token)
    setUserData(user)
    setAuthToken(token)
    setLoading(false)
    if (!user?.custom_registered) {
      setShowInvitationDialog(true)
    }
  }

  function showEmailNotVerifiedDialog() {
    setLoading(false)
  }

  useEffect(() => {
    if (isLoading) return
    if (isAuthenticated && !user?.email_verified) {
      return showEmailNotVerifiedDialog()
    }
    updateAccessToken()
  }, [isLoading])


  const handleCloseDialog = () => {
    if (!user?.email_verified) {
      logout()
    }
    navigate("/home")
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Dialog open={true} onClose={handleCloseDialog}>
          <DialogTitle>{user?.email_verified ? 'Login successful' : 'Email not verified'}</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <InvitationsDialog open={showInvitationDialog} onClose={handleCloseDialog}></InvitationsDialog>
    </div>
  );
};

export default LoginRedirect;