export function setAuthToken(token: string) {
  window.localStorage.setItem("token", token);
}
export function getAuthToken() {
  return window.localStorage.getItem("token");
}
export function setApiKey(api_key: string) {
  window.localStorage.setItem("api_key", api_key);
}
export function getApiKey() {
  return window.localStorage.getItem("api_key");
}

export function setUserData(user_data: any) {
  window.localStorage.setItem("user_data", JSON.stringify(user_data));
}

export function getUserData() {
  const result = window.localStorage.getItem("user_data");
  if (result) return JSON.parse(result);
  return null;
}

export function clearAuthData() {
  window.localStorage.clear();
}
