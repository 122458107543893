import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  IconButton,
  Snackbar,
  Alert,
  Checkbox
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { Api, Subscription } from "../../services/api.ts";

interface Member {
  id: number;
  user_id: number;
  display_name: string;
  role: string;
}

function Team() {
  const { id } = useParams<{ id: string }>(); // `id` corresponds to `:id` in the route
  const [idNumber, setIdNumber] = useState(0)
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<Member[]>([]);
  const [invitedMembers, setInvitedMembers] = useState<any[]>([]);
  const [activeSubscription, setActiveSubscription] = useState<Subscription | null>()
  const [openInvite, setOpenInvite] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const [openCancelMembership, setOpenCancelMembership] = useState(false);
  const [openCancelInvitation, setOpenCancelInvitation] = useState(false);
  const [selectedInvitationId, setSelectedInvitationId] = useState<number | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  useEffect(() => {
    setIdNumber(Number(id))
  }, [id]);

  useEffect(() => {
    if (!idNumber) return
    getMembers();
    getInvitedMembers();
    getActiveSubscription();
  }, [idNumber])

  const handleSubscriptionDetail = async () => {
    const res = await Api.initSubscriptionManagementPortal(idNumber)
    window.location.href = res.data.redirect_url
  };

  const getActiveSubscription = async () => {
    const res = await Api.getTeamActiveSubscription(idNumber)
    if (res.data.id)
      setActiveSubscription(res.data)
  }
  const getMembers = async () => {
    try {
      const res = await Api.getMembers(idNumber)

      setMembers(res.data);
    } catch (error) {
      setSnackbarMessage("Failed to fetch members");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const getInvitedMembers = async () => {
    try {
      const res = await Api.getInvitedMembers(idNumber)
      setInvitedMembers(res.data.results);
    } catch (error) {
      setSnackbarMessage("Failed to fetch invited members");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenInvite = () => setOpenInvite(true);
  const handleCloseInvite = () => {
    setOpenInvite(false);
    setInviteEmail("");
  };

  const handleInviteUser = async () => {
    try {
      await Api.inviteUser(idNumber, inviteEmail, isAdmin);

      handleCloseInvite();
      getInvitedMembers()
    } catch (error) {
      setSnackbarMessage("Failed to invite user");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCancelMembership = async () => {
    try {
      await Api.cancelMembership(idNumber);

      setSnackbarMessage("Membership canceled successfully");
      setSnackbarSeverity("success");
      setOpenCancelMembership(false);
      // Optionally refresh member list or redirect
    } catch (error) {
      setSnackbarMessage("Failed to cancel membership");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCancelInvitation = async () => {
    if (selectedInvitationId === null) return;
    try {
      await Api.cancelInvitation(idNumber, selectedInvitationId);
      setSnackbarMessage("Invitation canceled successfully");
      setSnackbarSeverity("success");
      setOpenCancelInvitation(false);
      getInvitedMembers(); // Refresh invited members list
    } catch (error) {
      setSnackbarMessage("Failed to cancel invitation");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Team Management
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button variant="contained" color="primary" onClick={handleOpenInvite}>
              Invite User
            </Button>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="flex-end">
            {activeSubscription ? (
              <Button
                onClick={handleSubscriptionDetail}
                variant="outlined"
                color="error">
                {activeSubscription?.items[0].price.product_name}
              </Button>
            ) : (<Link to={`/dashboard/team/${id}/subscription`}
            >
              <Button
                variant="outlined"
                color="error"
              >
                No active subscription
              </Button>
            </Link>)}
          </Grid>
        </Grid>
        <Dialog open={openInvite} onClose={handleCloseInvite}>
          <DialogTitle>Invite User</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="User Email"
              type="email"
              fullWidth
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
            />
            <div>Is admin</div>
            <Checkbox
              checked={isAdmin}
              onChange={() => setIsAdmin(!isAdmin)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseInvite} color="primary">
              Cancel
            </Button>
            <Button onClick={handleInviteUser} color="primary">
              Send Invite
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openCancelMembership} onClose={() => setOpenCancelMembership(false)}>
          <DialogTitle>Cancel Membership</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to cancel your membership?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCancelMembership(false)} color="primary">
              No
            </Button>
            <Button onClick={handleCancelMembership} color="error">
              Yes, Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openCancelInvitation} onClose={() => setOpenCancelInvitation(false)}>
          <DialogTitle>Cancel Invitation</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to cancel this invitation?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCancelInvitation(false)} color="primary">
              No
            </Button>
            <Button onClick={handleCancelInvitation} color="error">
              Yes, Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Members
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <CircularProgress />
                      <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                        Loading members...
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : members.length > 0 ? (
                  members.map((member, index) => (
                    <TableRow key={member.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{member.display_name}</TableCell>
                      <TableCell>{member.role}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No members available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Invited Users
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                      <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                        Loading invited users...
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : invitedMembers.length > 0 ? (
                  invitedMembers.map((invitation, index) => (
                    <TableRow key={invitation.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{invitation.email}</TableCell>
                      <TableCell>{invitation.role}</TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setSelectedInvitationId(invitation.id);
                            setOpenCancelInvitation(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No invited users
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container >
  );
}

export default Team;
