import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Chip,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { createProfile, editProfile, loadAliases, loadExtractors, loadWhitelists } from '../../../services/obstract.ts';

interface AddEntryDialogProps {
  open: boolean;
  onClose: () => void;
  onAddEntry: () => void;
  entryData?: any;
}

const AddEntryDialog: React.FC<AddEntryDialogProps> = ({
  open,
  onClose,
  onAddEntry,
  entryData, // Accept entry data for editing
}) => {
  const [listObj, setListObj] = useState({
    extractions: '',
    whitelists: '',
    aliases: '',
  });

  const [aliases, setAliases] = useState<{ name: string; id: string }[]>([]);
  const [extractions, setExtractions] = useState<{ name: string; id: string }[]>([]);
  const [whitelists, setWhitelists] = useState<{ name: string; id: string }[]>([]);
  const [aliasIdToNameDict, setAliasIdToNameDict] = useState({});
  const [extracorIdToNameDict, setExtractorIdToNameDict] = useState({});
  const [whitelistIdToNameDict, setWhitelistIdToNameDict] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    extractions: [],
    whitelists: [],
    aliases: [],
    relationship_mode: 'ai',
    extract_text_from_image: true,
  });

  const loadList = async (func: () => Promise<[]>, setter: React.Dispatch<React.SetStateAction<{ name: string; id: string }[]>>, dictSetter: React.Dispatch<React.SetStateAction<{}>>) => {
    const results = await func()
    setter(results);
    const dict: any = {};
    results.forEach((item: { id: string, name: string }) => {
      dict[item.id] = item.name;
    });
    dictSetter(dict);
  };

  useEffect(() => {
    loadList(loadAliases, setAliases, setAliasIdToNameDict);
    loadList(loadExtractors, setExtractions, setExtractorIdToNameDict);
    loadList(loadWhitelists, setWhitelists, setWhitelistIdToNameDict);
  }, []);

  useEffect(() => {
    // Load existing entry data for editing
    if (entryData) {
      setFormData({
        name: entryData.name || '',
        extractions: entryData.extractions || [],
        whitelists: entryData.whitelists || [],
        aliases: entryData.aliases || [],
        relationship_mode: entryData.relationship_mode || 'ai',
        extract_text_from_image: entryData.extract_text_from_image !== undefined ? entryData.extract_text_from_image : true,
      });
    } else {
      // Reset form for adding a new entry
      setFormData({
        name: '',
        extractions: [],
        whitelists: [],
        aliases: [],
        relationship_mode: 'ai',
        extract_text_from_image: true,
      });
    }
  }, [entryData, open]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleArrayDelete = (arrayName: string, index: number) => {
    const newArray = [...(formData[arrayName as keyof typeof formData] as string[])];
    newArray.splice(index, 1);
    setFormData((prev) => ({ ...prev, [arrayName]: newArray }));
  };

  const addEntry = async (data: any) => {
    try {
      await createProfile(data)
    } catch (err) {
      if (err.response.status === 400) {
        console.log(err.response.data);
      }
    }
  };

  const editEntry = async (id: string, data: any) => {
    try {
      await editProfile(id, data);
    } catch (err) {
      if (err.response.status === 400) {
        console.log(err.response.data);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (entryData) {
      await editEntry(entryData.id, formData); // Update existing entry
    } else {
      await addEntry(formData); // Add new entry
    }
    setFormData({
      name: '',
      extractions: [],
      whitelists: [],
      aliases: [],
      relationship_mode: 'ai',
      extract_text_from_image: true,
    });
    onAddEntry();
  };

  const handleArrayChangeNew = (arrayName: string, value: string) => {
    setListObj((prev) => ({ ...prev, [arrayName]: value }));
  };

  const handleArrayAdded = (arrayName: string) => {
    if (!listObj[arrayName]) return;
    const newArray = [...(formData[arrayName as keyof typeof formData] as string[])];
    newArray.push(listObj[arrayName]);
    setListObj((prev) => ({ ...prev, [arrayName]: '' }));
    setFormData((prev) => ({ ...prev, [arrayName]: newArray }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{entryData ? 'Edit Profile' : 'Add Profile'}</DialogTitle>
      <DialogContent style={{ width: '30vw' }}>
        <DialogContentText>
          Please enter the details of the {entryData ? 'profile to edit' : 'new profile'}.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={formData.name}
          onChange={handleChange}
          required
        />

        {/* Extractions */}
        <Box marginY={2}>
          <div>
            <strong>Extractions:</strong>
            {formData.extractions.map((extraction, index) => (
              <Chip
                key={index}
                label={extracorIdToNameDict[extraction]}
                onDelete={() => handleArrayDelete('extractions', index)}
                style={{ margin: '4px' }}
              />
            ))}
          </div>
          <Box display="flex" alignItems="center">
            <Select
              style={{ flex: 'auto' }}
              name="extractions"
              value={listObj.extractions}
              onChange={(e) => handleArrayChangeNew('extractions', e.target.value)}
            >
              {extractions.map((extraction) => (
                <MenuItem key={extraction.id} value={extraction.id}>{extraction.name}</MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleArrayAdded('extractions')}
              style={{ marginLeft: '8px' }}
            >
              Add
            </Button>
          </Box>
        </Box>

        {/* Whitelists */}
        <Box marginY={2}>
          <div>
            <strong>Whitelists:</strong>
            {formData.whitelists.map((whitelist, index) => (
              <Chip
                key={index}
                label={whitelistIdToNameDict[whitelist]}
                onDelete={() => handleArrayDelete('whitelists', index)}
                style={{ margin: '4px' }}
              />
            ))}
          </div>
          <Box display="flex" alignItems="center">
            <Select
              style={{ flex: 'auto' }}
              name="whitelists"
              value={listObj.whitelists}
              onChange={(e) => handleArrayChangeNew('whitelists', e.target.value)}
            >
              {whitelists.map((whitelist) => (
                <MenuItem key={whitelist.id} value={whitelist.id}>{whitelist.name}</MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleArrayAdded('whitelists')}
              style={{ marginLeft: '8px' }}
            >
              Add
            </Button>
          </Box>
        </Box>

        {/* Aliases */}
        <Box marginY={2}>
          <div>
            <strong>Aliases:</strong>
            {formData.aliases.map((alias, index) => (
              <Chip
                key={index}
                label={aliasIdToNameDict[alias]}
                onDelete={() => handleArrayDelete('aliases', index)}
                style={{ margin: '4px' }}
              />
            ))}
          </div>
          <Box display="flex" alignItems="center">
            <Select
              style={{ flex: 'auto' }}
              name="aliases"
              value={listObj.aliases}
              onChange={(e) => handleArrayChangeNew('aliases', e.target.value)}
            >
              {aliases.map((alias) => (
                <MenuItem key={alias.id} value={alias.id}>{alias.name}</MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleArrayAdded('aliases')}
              style={{ marginLeft: '8px' }}
            >
              Add
            </Button>
          </Box>
        </Box>

        <FormControl fullWidth margin="dense">
          <InputLabel>Relationship Mode</InputLabel>
          <Select
            name="relationship_mode"
            value={formData.relationship_mode}
            onChange={(e) => setFormData({ ...formData, relationship_mode: e.target.value })}
          >
            <MenuItem value="ai">AI</MenuItem>
            <MenuItem value="standard">Standard</MenuItem>
          </Select>
        </FormControl>

        <Box mb={4}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.extract_text_from_image}
                color="primary"
                onChange={(e) => setFormData({ ...formData, extract_text_from_image: e.target.checked })}
              />
            }
            label="Extract text from image"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {entryData ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEntryDialog;
