import React from 'react';
import { Card, CardContent, Typography, IconButton, Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { deleteObstractFeed } from '../../../services/obstract.ts';

type Feed = {
    id: string;
    count_of_posts: number;
    title: string;
    description: string;
    url: string;
    earliest_item_pubdate: string;
    latest_item_pubdate: string;
    datetime_added: string;
    feed_type: string;
    include_remote_blogs: boolean;
};

interface FeedCardProps {
    feed: Feed;
    onEdit: () => void;
    onDelete: () => void;
}

const FeedCard: React.FC<FeedCardProps> = ({ feed, onEdit, onDelete }) => {
    const handleDelete = async () => {
        await deleteObstractFeed(feed.id);
        onDelete();
    };

    return (
        <Card variant="outlined" style={{ margin: '16px', padding: '16px', width: '30%' }}>
            <CardContent>
                <Typography variant="h5">{feed.title}</Typography>
                <Typography variant="body2">{feed.description}</Typography>
                <Typography variant="caption">Posts: {feed.count_of_posts}</Typography>
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onEdit}
                        style={{ marginRight: '8px' }}
                    >
                        Reload
                    </Button>
                    <IconButton color="secondary" onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                    <Link to={`/dashboard/obstract/profiles/${feed.id}/posts`}>
                        <Button>Posts</Button></Link>
                </Box>
            </CardContent>
        </Card>
    );
};

export default FeedCard;
