import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Homepage from "./pages/homepage";
import LoginRedirect from "./pages/login-redirect/index.tsx";
import Dashboard from "./pages/dashboard";
import Teams from "./pages/teams/index.tsx";
import Team from "./pages/team/index.tsx";
import LogoutRedirect from "./pages/logout-redirect/index.tsx";
import UserProfile from "./pages/profile/index.tsx";
import AuthGuard from "./guards/auth.guard.tsx";
import SubscriptionManagement from "./pages/subscription/index.tsx";
import Invitations from "./pages/teams/invitations.tsx";
import Logout from "./pages/dashboard/logout/index.jsx";
import ObstractProfile from "./pages/obstract/profile/index.tsx";
import FeedsPage from "./pages/obstract/feeds/index.tsx";
import PostPage from "./pages/obstract/posts/index.tsx";
import ConfirmSubscription from "./pages/subscription/confirm-subscription.tsx";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID

const router = createBrowserRouter([
  {
    path: "/",
    Component: Homepage,
  },
  {
    path: "/login-redirect",
    Component: LoginRedirect,
  },
  {
    path: "/logout",
    Component: Logout,
  },
  {
    path: "/logout-redirect",
    Component: LogoutRedirect,
  },
  {
    path: "/login",
    Component: LogoutRedirect,
  },
  {
    path: "/home",
    Component: Dashboard,
  },
  {
    path: "/dashboard",
    element: <AuthGuard>
      <Dashboard></Dashboard>
    </AuthGuard>,
    children: [
      {
        path: "profile",
        Component: UserProfile,
      },
      {
        path: "teams",
        Component: Teams,
      },
      {
        path: "invites",
        Component: Invitations,
      },
      {
        path: "team/:id",
        Component: Team,
      },
      {
        path: "team/:team_id/subscription",
        Component: SubscriptionManagement,
      },

      {
        path: "team/:team_id/subscription/confirm",
        Component: ConfirmSubscription,
      },
      {
        path: "obstract/profiles",
        Component: ObstractProfile,
      },
      {
        path: "obstract/feeds",
        Component: FeedsPage,
      },
      {
        path: "obstract/profiles/:feed_id/posts",
        Component: PostPage,
      },

    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin + "/login-redirect",
    }}
  >
    <RouterProvider router={router} />
  </Auth0Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
