import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, CircularProgress, Pagination } from '@mui/material';
import { fetchObstractPosts, Post } from '../../../services/obstract.ts';
import PostCard from './post-card.tsx';
import EditPostModal from './edit-modal.tsx';
import { useParams } from 'react-router-dom';

const PostPage: React.FC = () => {
    const { feed_id } = useParams<{ feed_id: string }>(); // `id` corresponds to `:id` in the route
    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const [page, setPage] = useState<number>(1); // Current page
    const [pageSize] = useState<number>(10); // Posts per page
    const [totalPages, setTotalPages] = useState<number>(1); // Total pages
    const [selectedPost, setSelectedPost] = useState<Post | null>(null);
    const [modalOpen, setModalOpen] = useState(false);

    const loadData = async (feed_id: string, pageNumber: number) => {
        setLoading(true);
        const res = await fetchObstractPosts(feed_id, pageNumber);
        setPosts(res.data.posts);
        setTotalPages(Math.ceil(res.data.page_results_count / res.data.page_size)); // Calculate total pages
        setLoading(false);
    };

    useEffect(() => {
        if (!feed_id) return;
        loadData(feed_id, page);
    }, [feed_id, page]);

    if (!feed_id) {
        return <div></div>
    }

    const handleOpenModal = (post?: Post) => {
        setSelectedPost(post || null);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedPost(null);
    };

    const handleOnSave = () => {
        setModalOpen(false);
        loadData(feed_id, page)
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value); // Update current page
    };

    return (
        <Box>
            <Typography variant="h4" mb={4}>
                Posts
            </Typography>

            {/* Show loading spinner while data is being fetched */}
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                <Box mt={2}>
                    {posts.map((post) => (
                        <PostCard key={post.id} post={post} feed_id={feed_id ?? ''} onEdit={() => handleOpenModal(post)} />
                    ))}
                </Box>
            )}

            {/* Pagination controls */}
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
            />

            <EditPostModal
                open={modalOpen}
                onClose={handleCloseModal}
                post={selectedPost}
                onSave={handleOnSave}
                feed_id={feed_id}
            />
        </Box>
    );
};

export default PostPage;
