import React, { useEffect, useState } from "react";
import {
    Container,
    Typography,
    TextField,
    Button,
    Box,
    Switch,
    FormControlLabel,
    Snackbar,
    Alert,
    Modal,
    IconButton
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { QRCodeCanvas } from 'qrcode.react';
import CloseIcon from '@mui/icons-material/Close';
import { Api } from "../../services/api.ts";

const UserProfile = () => {
    const { user } = useAuth0();
    const [email, setEmail] = useState("");
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
    const [loading, setLoading] = useState(false)

    // Modal state
    const [modalOpen, setModalOpen] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpKey, setOtpKey] = useState("");
    const [totpSecret, setTotpSecret] = useState("");

    useEffect(() => {
        console.log(user)
        setEmail(user?.email || "");
        setIs2FAEnabled(user?.custom_mfa_enabled)
    }, [user]);

    const handleUpdateEmail = async () => {
        try {
            setLoading(true)
            await Api.changeUserEmail(email);
            setLoading(false)
            setSnackbarMessage("Email updated successfully, check your email to verify it");
            setSnackbarSeverity("success");
        } catch (error) {
            setLoading(false)

            setSnackbarMessage("Failed to update email");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleChangePassword = async () => {
        try {
            setLoading(true)

            await Api.changeUserPassword();
            setLoading(false)

            setSnackbarMessage("Check your mail to complete the process");
            setSnackbarSeverity("success");
        } catch (error) {
            setLoading(false)

            setSnackbarMessage("Failed to change password");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleDisable2FA = async () => {
        try {
            setLoading(true)
            await Api.disableOTP();
            setLoading(false)
            setIs2FAEnabled(false);
            setSnackbarMessage("2FA disabled successfully");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } catch (error) {
            setLoading(false)

            setSnackbarMessage("Failed to toggle 2FA");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const initToggle2FA = async () => {
        try {
            setLoading(true)

            const res = await Api.initializeOTP();
            setLoading(false)

            if (res.data.data.totp_uri) {
                setTotpSecret(res.data.data.totp_uri);
                setOtpKey(res.data.data.totp_secret)
                setModalOpen(true); // Open the modal to scan OTP
            }
        } catch (error) {
            setLoading(false)

            setSnackbarMessage("Failed to toggle 2FA");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleOtpSubmit = async () => {
        try {
            setLoading(true)

            await Api.verifyOTP(otp, otpKey);
            setLoading(false)

            setSnackbarMessage("2FA setup successful");
            setSnackbarSeverity("success");
            setModalOpen(false); // Close the modal after successful setup
            setIs2FAEnabled(true)
        } catch (error) {
            setLoading(false)

            setSnackbarMessage("Failed to verify OTP");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" gutterBottom>
                    User Management
                </Typography>
                <Box mb={4}>
                    <Typography variant="h6" gutterBottom>
                        Update Email
                    </Typography>
                    <TextField
                        fullWidth
                        label="New Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleUpdateEmail} disabled={loading}>
                        Update Email
                    </Button>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" gutterBottom>
                        Change Password
                    </Typography>
                    <TextField
                        fullWidth
                        label="New Password"
                        type="password"
                        value="************"
                        disabled
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleChangePassword} disabled={loading}>
                        Change Password
                    </Button>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" gutterBottom>
                        Two-Factor Authentication
                    </Typography>
                    <FormControlLabel

                        control={
                            <Switch
                                checked={is2FAEnabled}
                                color="primary"
                                disabled
                            />
                        }
                        label={is2FAEnabled ? "Disable 2FA" : "Enable 2FA"}
                    />
                    {
                        is2FAEnabled ? (<Button onClick={handleDisable2FA}>Turn off 2fa</Button>) : (
                            <Button onClick={initToggle2FA} disabled={loading}>Turn on 2fa</Button>
                        )
                    }
                </Box>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity={snackbarSeverity}
                        sx={{ width: '100%' }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setModalOpen(false)}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" gutterBottom>
                            Set Up Two-Factor Authentication
                        </Typography>
                        <Box mb={2}>
                            <QRCodeCanvas value={totpSecret} />
                        </Box>
                        <TextField
                            fullWidth
                            label="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            margin="normal"
                        />
                        <Button variant="contained" color="primary" onClick={handleOtpSubmit} disabled={loading}>
                            Verify OTP
                        </Button>
                    </Box>
                </Modal>
            </Box>
        </Container>
    );
};

export default UserProfile;
