import React from 'react';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  Container, 
  Typography, 
  Button, 
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import SecurityIcon from '@mui/icons-material/Security';
import SupportIcon from '@mui/icons-material/Support';
import LoginButton from '../../components/login_button/index.tsx';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h3: {
      fontWeight: 700,
    },
  },
});

const features = [
  { title: 'Fast Performance', description: 'Lightning-fast load times and smooth interactions.', icon: <SpeedIcon fontSize="large" color="primary" /> },
  { title: 'Secure & Reliable', description: 'Your data is protected with top-notch security measures.', icon: <SecurityIcon fontSize="large" color="primary" /> },
  { title: '24/7 Support', description: 'Our dedicated team is always here to help you.', icon: <SupportIcon fontSize="large" color="primary" /> },
];

function LandingPage() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Obstract
          </Typography>
          <Button color="inherit">Sign Up</Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Welcome to Obstract
          </Typography>
          <Typography variant="h5" component="h2" color="text.secondary" paragraph>
            Experience the future of productivity with our cutting-edge application.
          </Typography>
   
          <LoginButton text></LoginButton>
        </Box>

        <Grid container spacing={4} justifyContent="center" sx={{ mb: 8 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  {feature.icon}
                  <Typography gutterBottom variant="h5" component="h2" sx={{ mt: 2 }}>
                    {feature.title}
                  </Typography>
                  <Typography>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ position: 'relative', mb: 8 }}>
          <CardMedia
            component="img"
            height="400"
            image="https://source.unsplash.com/random/1200x400/?technology"
            alt="Technology"
            sx={{ borderRadius: 2 }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(0,0,0,0.5)',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 2,
            }}
          >
            <Typography variant="h4" component="h3" gutterBottom>
              Join Thousands of Satisfied Users
            </Typography>
            <Button variant="contained" color="secondary" size="large">
              Get Started
            </Button>
          </Box>
        </Box>

        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} Obstract. All rights reserved.
        </Typography>
      </Container>
    </ThemeProvider>
  );
}

export default LandingPage;