import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Grid,
    CircularProgress,
    Pagination
} from '@mui/material';
import FeedFormModal from './feed-modal.tsx';
import FeedCard from './feed-card.tsx';
import { fetchObstractFeeds } from '../../../services/obstract.ts';

type Feed = {
    id: string;
    count_of_posts: number;
    title: string;
    description: string;
    url: string;
    earliest_item_pubdate: string;
    latest_item_pubdate: string;
    datetime_added: string;
    feed_type: string;
    include_remote_blogs: boolean;
};

const FeedsPage: React.FC = () => {
    const [feeds, setFeeds] = useState<Feed[]>([]);
    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const [page, setPage] = useState<number>(1); // Current page
    const [pageSize] = useState<number>(10); // Feeds per page
    const [totalPages, setTotalPages] = useState<number>(1); // Total number of pages
    const [openModal, setOpenModal] = useState(false);
    const [selectedFeed, setSelectedFeed] = useState<Feed | null>(null);
    const [formData, setFormData] = useState<Partial<Feed>>({});

    const loadFeeds = async (pageNumber: number) => {
        setLoading(true);
        const response = await fetchObstractFeeds(pageNumber);
        setFeeds(response.data.feeds);
        setTotalPages(Math.ceil(response.data.total_results_count / response.data.page_size)); // Calculate total pages
        setLoading(false);
    };

    useEffect(() => {
        loadFeeds(page);
    }, [page]);

    const handleModalOpen = (feed?: Feed) => {
        setSelectedFeed(feed || null);
        setFormData(feed || {});
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setSelectedFeed(null);
        setFormData({});
        setOpenModal(false);
    };

    const editFeed = (feed: Feed) => {
        setSelectedFeed(feed);
        setFormData({});
        setOpenModal(true);
    };

    const reloadData = () => {
        setOpenModal(false)
        loadFeeds(page)
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value); // Update current page
    };

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                Feed Management
            </Typography>
            <Button variant="contained" color="primary" onClick={() => handleModalOpen()} style={{ marginBottom: '16px' }}>
                Add New Feed
            </Button>

            {/* Show loading spinner while data is being fetched */}
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                <Grid container spacing={3}>
                    {feeds.map((feed) => (
                        <FeedCard key={feed.id} onEdit={() => editFeed(feed)} onDelete={reloadData} feed={feed}></FeedCard>
                    ))}
                </Grid>
            )}

            {/* Pagination controls */}
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
            />

            <FeedFormModal
                open={openModal}
                onClose={handleModalClose}
                onAddEntry={reloadData}
                entryData={selectedFeed}
            />
        </Box>
    );
};

export default FeedsPage;
