// TeamManagement.tsx
import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import { Api } from "../../services/api.ts";

interface Team {
  id?: number;
  name: string;
  slug: string;
}

interface TeamManagementProps {
  open: boolean;
  onClose: () => void;
  team: Team | null;
  onTeamUpdated: () => void;
}

function TeamManagement({ open, onClose, team, onTeamUpdated }: TeamManagementProps) {
  const [teamName, setTeamName] = useState("");
  const [teamSlug, setTeamSlug] = useState("");

  useEffect(() => {
    if (team) {
      setTeamName(team.name);
      setTeamSlug(team.slug);
    } else {
      setTeamName("");
      setTeamSlug("");
    }
  }, [team]);

  const handleSave = async () => {
    try {
      if (team?.id) {
        await Api.updateTeam(team.id, { name: teamName, slug: teamSlug });
      } else {
        await Api.createTeam(teamName);
      }
      onClose();
      onTeamUpdated();
    } catch (error) {
      console.error("Failed to save team:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{team?.id ? "Edit Team" : "Add New Team"}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Team Name"
          fullWidth
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          {team?.id ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TeamManagement;
