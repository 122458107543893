import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
} from '@mui/material';
import { createObstractFeed, fetchObstractProfiles, Profile, relaodObstractFeed } from '../../../services/obstract.ts';

interface AddEntryDialogProps {
  open: boolean;
  onClose: () => void;
  onAddEntry: () => void;
  entryData?: {
    id: string;
    url: string;
    include_remote_blogs: boolean;
  } | null;
}

const AddEntryDialog: React.FC<AddEntryDialogProps> = ({
  open,
  onClose,
  onAddEntry,
  entryData,
}) => {
  const [formData, setFormData] = useState({
    profile_id: '',
    url: '',
    include_remote_blogs: false,
  });
  const [profiles, setProfiles] = useState<Profile[]>([]);

  const loadProfiles = async (pageNumber: number) => {
    const res = await fetchObstractProfiles(pageNumber);
    setProfiles(res.data.profiles);
  };

  useEffect(() => {
    loadProfiles(1)
  }, [])

  useEffect(() => {
    // Load existing entry data for editing
    if (entryData) {
      setFormData({
        profile_id: '',
        url: entryData.url || '',
        include_remote_blogs: entryData.include_remote_blogs || false,
      });
    } else {
      // Reset form for adding a new entry
      setFormData({
        profile_id: '',
        url: '',
        include_remote_blogs: false,
      });
    }
  }, [entryData, open]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleProfileChange = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      profile_id: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (entryData) {
        await relaodObstractFeed(entryData.id, formData); // Update existing entry
      } else {
        await createObstractFeed(formData); // Add new entry
      }
      setFormData({
        profile_id: '',
        url: '',
        include_remote_blogs: false,
      });
      onAddEntry();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        console.log(err.response.data);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{entryData ? 'Reload Feed' : 'Add New Feed'}</DialogTitle>
      <DialogContent style={{ minWidth: '30vw' }}>

        <Box marginY={2} style={{ display: 'flex', flexDirection: 'column' }}>
          <Box>
            <strong>Profile:</strong>
          </Box>
          <Select
            style={{ flex: 'auto' }}
            name="extractions"
            value={formData.profile_id}
            onChange={(e) => handleProfileChange(e.target.value)}
          >
            {profiles.map((profile) => (
              <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
            ))}
          </Select>
        </Box>

        {entryData ? (<></>) : (
          <>


            <TextField
              margin="dense"
              name="url"
              label="URL"
              type="url"
              fullWidth
              value={formData.url}
              onChange={handleChange}
              required
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.include_remote_blogs}
                  onChange={handleChange}
                  name="include_remote_blogs"
                  color="primary"
                />
              }
              label="Include Remote Blogs"
            />
          </>)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {entryData ? 'Reload' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEntryDialog;
