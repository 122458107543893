import axios from 'axios';
import { getApiKey } from './storage.ts';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api'

type Paginated<T> = {
    results: T
}

// Common function for API requests with Authorization headers
const apiRequest = async <T>(
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
    path: string,
    data?: any,
    headers: Record<string, string> = {}
) => {
    try {
        const response = await axios<T>({
            method,
            url: `${API_BASE_URL}${path}`,
            data,
            headers: {
                Authorization: `Token ${getApiKey()}`,
                ...headers,
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

// Authentication API
const authApi = async (accessToken: string): Promise<string> => {
    const { data } = await axios.post<{ key: string; new_user: boolean }>(
        `${API_BASE_URL}/rest-auth/auth0/`,
        { access_token: accessToken }
    );
    return data.key;
};

// API functions
const createTeam = async (name: string) => {
    return apiRequest<{ id: number }>('POST', '/teams/api/teams/', { name });
};

const changeUserEmail = async (email: string) => {
    return apiRequest<void>('PATCH', '/users/user-management/change-email/', { email });
};

const changeUserPassword = async () => {
    return apiRequest<void>('POST', '/users/user-management/change-password/');
};

const disableOTP = async () => {
    return apiRequest<void>('POST', '/users/user-management/disable-otp/');
};

const initializeOTP = async () => {
    return apiRequest<any>('POST', '/users/user-management/init-otp/');
};

const verifyOTP = async (otp: string, otpKey: string) => {
    return apiRequest<void>('POST', '/users/user-management/verify-otp/', { otp, otp_key: otpKey });
};

const cancelInvite = async (invitationId: number) => {
    return apiRequest<void>('POST', `/teams/api/user/invitations/${invitationId}/cancel-invitation/`);
};

const acceptInvite = async (invitationId: number) => {
    return apiRequest<void>('POST', `/teams/api/user/invitations/${invitationId}/accept-invitation/`);
};

const completeRegistration = async (data: {}) => {
    return apiRequest<void>('POST', `/teams/api/user/complete-registration/`, data);
};

const updateTeam = async (teamId: number, teamData: { name: string; slug: string }) => {
    return apiRequest<void>('PUT', `/teams/api/teams/${teamId}/`, teamData);
};

const fetchTeams = async () => {
    return apiRequest<Paginated<any[]>>('GET', '/teams/api/teams/');
};

const fetchMyInvitations = async () => {
    return apiRequest<Paginated<any[]>>('GET', '/teams/api/user/invitations/').then(response => {
        console.log(response)
        console.log(response.data)
        return response.data.results
    });
};

const getMembers = async (teamId: number) => {
    return apiRequest<any>('GET', `/teams/api/teams/${teamId}/members/`);
};

const getInvitedMembers = async (teamId: number) => {
    return apiRequest<Paginated<any[]>>('GET', `/teams/api/teams/${teamId}/invitations/?is_accepted=false`);
};

const inviteUser = async (teamId: number, email: string, isAdmin: boolean) => {
    return apiRequest<void>('POST', `/teams/api/teams/${teamId}/invitations/`, { email, role: isAdmin ? 'admin' : 'member' });
};

const cancelMembership = async (teamId: number) => {
    return apiRequest<void>('POST', `/teams/api/teams/${teamId}/cancel/`);
};

const cancelInvitation = async (teamId: number, invitationId: number) => {
    return apiRequest<void>('DELETE', `/teams/api/teams/${teamId}/invitations/${invitationId}/`);
};

type Price = {
    id: string;
    product_name: string;
    human_readable_price: string;
    payment_amount: string;
    nickname: string;
    unit_amount: number;
    recurring_type: string
};

export type Product = {
    djstripe_id: number;
    prices: Price[];
    djstripe_created: string; // ISO 8601 date string
    djstripe_updated: string; // ISO 8601 date string
    id: string;
    livemode: boolean;
    created: string; // ISO 8601 date string
    metadata: Record<string, any>;
    description: string;
    name: string;
    type: string;
    active: boolean;
    attributes: any[];
    caption: string;
    deactivate_on: any[] | null;
    images: string[];
    package_dimensions: any | null;
    shippable: boolean | null;
    url: string;
    statement_descriptor: string;
    unit_label: string;
    djstripe_owner_account: string;
    default_price: string;
};


const fetchSubscriptionPlans = async () => {
    return apiRequest<Paginated<Product[]>>('GET', `/subscriptions/api/plans/`);
}


const changeSubscriptionPlan = async (teamId: number, priceId: string) => {
    return apiRequest<{ redirect_url: string }>('POST', `/team-management/${teamId}/subscription/subscription/init/`, {
        price_id: priceId
    });
};

const confirmChangeSubscriptionPlan = async (teamId: number, sessionId: string) => {
    return apiRequest<{ redirect_url: string }>('POST', `/team-management/${teamId}/subscription/subscription/init/confirm-subscription/`, {
        session_id: sessionId
    });
};


const initSubscriptionManagementPortal = async (teamId: number) => {
    return apiRequest<{ redirect_url: string }>('POST', `/team-management/${teamId}/subscription/subscription/init/create-portal-session/`, {
    });
};


interface SubscriptionItem {
    id: string;
    price: Price;
    quantity: number;
}

export interface Subscription {
    id: string;
    start_date: string; // ISO date string
    current_period_start: string; // ISO date string
    current_period_end: string; // ISO date string
    cancel_at_period_end: boolean;
    status: 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due' | 'trialing' | 'unpaid'; // Based on Stripe statuses
    quantity: number;
    items: SubscriptionItem[];
}


const getTeamActiveSubscription = (teamId: number) => {
    return apiRequest<Subscription>('GET', `/team-management/${teamId}/subscription/subscription/init/active-subscription/`)
}

export const Api = {
    createTeam,
    changeUserEmail,
    changeUserPassword,
    disableOTP,
    initializeOTP,
    verifyOTP,
    updateTeam,
    fetchTeams,
    fetchMyInvitations,
    cancelInvite,
    acceptInvite,
    completeRegistration,
    getMembers,
    getInvitedMembers,
    inviteUser,
    cancelMembership,
    cancelInvitation,
    authApi,
    changeSubscriptionPlan,
    fetchSubscriptionPlans,
    confirmChangeSubscriptionPlan,
    getTeamActiveSubscription,
    initSubscriptionManagementPortal,
};
