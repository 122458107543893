import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Box } from '@mui/material';
import { Home, People, Settings, ExitToApp } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const drawerWidth = 240;

const DashboardLayout = () => {
  const { user, logout } = useAuth0()
  const handleLogout = () => {
    logout()
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Typography variant="body1" sx={{ mr: 2 }}>
            {user?.name}
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}>
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          <ListItem button component={NavLink} to="/dashboard/teams">
            <ListItemIcon><People /></ListItemIcon>
            <ListItemText primary="Teams" />
          </ListItem>
          <ListItem button component={NavLink} to="/dashboard/invites">
            <ListItemIcon><Home /></ListItemIcon>
            <ListItemText primary="Team Invites" />
          </ListItem>
          <ListItem button component={NavLink} to="/dashboard/profile">
            <ListItemIcon><Settings /></ListItemIcon>
            <ListItemText primary="Profile Settings" />
          </ListItem>
          <ListItem button component={NavLink} to="/dashboard/obstract/profiles">
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Obstract profile" />
          </ListItem>
          <ListItem button component={NavLink} to="/dashboard/obstract/feeds">
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Obstract feeds" />
          </ListItem>
        </List>
      </Drawer>

      <Box
        component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
            <Toolbar />
            <Outlet />
          </Box>
        </Box>
        );
};

        export default DashboardLayout;
