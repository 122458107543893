import axios from "axios";

const OBSTRACT_API_BASE_URL = process.env.REACT_APP_OBSTRACT_API_BASE_URL

export type Profile = {
    id: string;
    created: string;
    name: string;
    extractions: string[];
    whitelists: string[];
    aliases: string[];
    relationship_mode: string;
    extract_text_from_image: boolean;
};

type ProfilesResponse = {
    page_size: number;
    page_number: number;
    page_results_count: number;
    total_results_count: number;
    profiles: Profile[];
};

type Feed = {
    id: string;                           // Unique identifier for the feed
    count_of_posts: number;               // Number of posts in the feed
    title: string;                        // Title of the feed
    description: string;                  // Description of the feed
    url: string;                          // URL of the feed
    earliest_item_pubdate: string;       // ISO 8601 formatted date string of the earliest item publication
    latest_item_pubdate: string;         // ISO 8601 formatted date string of the latest item publication
    datetime_added: string;               // ISO 8601 formatted date string of when the feed was added
    feed_type: 'rss' | 'atom';           // Type of feed, can be 'rss' or 'atom'
    include_remote_blogs: boolean;        // Flag indicating whether to include remote blogs
}

type FeedResponse = {
    page_size: number;
    page_number: number;
    page_results_count: number;
    total_results_count: number;
    feeds: Feed[];
};

export interface Post {
    id: string;                     // Unique identifier for the post
    datetime_added: string;        // ISO date string for when the post was added
    datetime_updated: string;      // ISO date string for when the post was last updated
    title: string;                 // Title of the post
    description: string;           // Description of the post
    link: string;                  // Link to the post
    pubdate: string;               // ISO date string for the publication date
    author: string;                // Author of the post
    is_full_text: boolean;         // Flag indicating if the post is full text
    content_type: string;          // Content type of the post
    categories: string[];          // Array of categories for the post
    profile_id: string;
}

interface PostsResponse {
    page_size: number;             // Number of posts per page
    page_number: number;           // Current page number
    page_results_count: number;    // Number of results on the current page
    total_results_count: number;   // Total number of posts
    posts: Post[];                 // Array of post objects
}


export const fetchObstractProfiles = (page_number: number) => {
    return axios.get<ProfilesResponse>(OBSTRACT_API_BASE_URL + '/api/v1/profiles/')
}
export const deleteObstractProfile = (id: string) => {
    return axios.delete<ProfilesResponse>(OBSTRACT_API_BASE_URL + `/api/v1/profiles/${id}/`)
}

export const fetchObstractFeeds = (page_number: number) => {
    return axios.get<FeedResponse>(OBSTRACT_API_BASE_URL + '/api/v1/feeds/')
}

export const fetchObstractPosts = (feed_id: string, page: number) => {
    return axios.get<PostsResponse>(OBSTRACT_API_BASE_URL + `/api/v1/feeds/${feed_id}/posts/`)
}


export const createObstractFeed = (data: {
    profile_id: string,
    url: string,
    include_remote_blogs: boolean
}) => {
    return axios.post<PostsResponse>(OBSTRACT_API_BASE_URL + `/api/v1/feeds/`, data)
}

export const deleteObstractFeed = (feed_id: string) => {
    return axios.delete<PostsResponse>(OBSTRACT_API_BASE_URL + `/api/v1/feeds/${feed_id}/`)
}


export const relaodObstractFeed = (feed_id: string, data: {
    profile_id: string,
    url: string,
    include_remote_blogs: boolean
}) => {
    return axios.patch<PostsResponse>(OBSTRACT_API_BASE_URL + `/api/v1/feeds/${feed_id}/`, data)
}


export const fetchPostMarkdown = (feed_id: string, post_id: string) => {
    return axios.get<PostsResponse>(OBSTRACT_API_BASE_URL + `/api/v1/feeds/${feed_id}/posts/${post_id}/markdown/`)
}

export const fetchPostObjects = (feed_id: string, post_id: string) => {
    return axios.get<PostsResponse>(OBSTRACT_API_BASE_URL + `/api/v1/feeds/${feed_id}/posts/${post_id}/objects/`)
}


export const changePostProfileId = (feed_id: string, post_id: string, profile_id: string) => {
    return axios.patch<PostsResponse>(OBSTRACT_API_BASE_URL + `/api/v1/feeds/${feed_id}/posts/${post_id}/`)
}

export const loadAliases = () => {
    return axios.get<any>(OBSTRACT_API_BASE_URL + `/api/v1/aliases/?page_size=100`).then(res => res.data.aliases)
}

export const loadExtractors = () => {
    return axios.get<any>(OBSTRACT_API_BASE_URL + `/api/v1/extractors/?page_size=100`).then(res => res.data.extractors)
}

export const loadWhitelists = () => {
    return axios.get<any>(OBSTRACT_API_BASE_URL + `/api/v1/whitelists/?page_size=100`).then(res => res.data.whitelists)
}

export const editProfile = async (id: string, data: any) => {
    return axios.patch(OBSTRACT_API_BASE_URL + `/api/v1/profiles/${id}/`, data);
}

export const createProfile = async (data: any) => {
    return axios.post(OBSTRACT_API_BASE_URL + '/api/v1/profiles/', data);
}