// Invitations.tsx
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Delete as DeleteIcon, Check as CheckIcon } from "@mui/icons-material";
import { Api } from "../../services/api.ts";

interface Invitation {
  id: number;
  email: string;
  role: string;
  team: {
    name: string
  }
}

function Invitations() {
  const [invitedTeams, setInvitedTeams] = useState<Invitation[]>([]);
  const [loading, setLoading] = useState(true);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [selectedInvitationId, setSelectedInvitationId] = useState<number | null>(null);

  async function loadInvitations() {
    try {
      const results = await Api.fetchMyInvitations()
      setInvitedTeams(results);
    } catch (error) {
      console.error("Failed to fetch invitations:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleOpenCancelDialog = (id: number) => {
    setSelectedInvitationId(id);
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setSelectedInvitationId(null);
  };

  const handleOpenAcceptDialog = (id: number) => {
    setSelectedInvitationId(id);
    setOpenAcceptDialog(true);
  };

  const handleCloseAcceptDialog = () => {
    setOpenAcceptDialog(false);
    setSelectedInvitationId(null);
  };

  const handleCancelInvitation = async () => {
    if (selectedInvitationId !== null) {
      try {
        await Api.cancelInvite(selectedInvitationId)
        loadInvitations();
      } catch (error) {
        console.error("Failed to cancel invitation:", error);
      } finally {
        handleCloseCancelDialog();
      }
    }
  };

  const handleAcceptInvitation = async () => {
    if (selectedInvitationId !== null) {
      try {
        await Api.acceptInvite(selectedInvitationId)
        loadInvitations();
      } catch (error) {
        console.error("Failed to accept invitation:", error);
      } finally {
        handleCloseAcceptDialog();
      }
    }
  };

  useEffect(() => {
    loadInvitations();
  }, []);

  return (
    <Box mt={4}>
      <Typography variant="h6" gutterBottom>
        Team invitations
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S/N</TableCell>
              <TableCell>Team name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                  <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                    Loading invited users...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : invitedTeams.length > 0 ? (
              invitedTeams.map((invitation, index) => (
                <TableRow key={invitation.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{invitation.team.name}</TableCell>
                  <TableCell>{invitation.role}</TableCell>
                  <TableCell>
                    <Tooltip title="Accept Invitation">
                      <IconButton
                        color="success"
                        onClick={() => handleOpenAcceptDialog(invitation.id)}
                      >
                        <CheckIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel Invitation">
                      <IconButton
                        color="error"
                        onClick={() => handleOpenCancelDialog(invitation.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No invited users
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Cancel Invitation Dialog */}
      <Dialog open={openCancelDialog} onClose={handleCloseCancelDialog}>
        <DialogTitle>Cancel Invitation</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to cancel this invitation?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCancelInvitation} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Accept Invitation Dialog */}
      <Dialog open={openAcceptDialog} onClose={handleCloseAcceptDialog}>
        <DialogTitle>Accept Invitation</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to accept this invitation?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAcceptDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAcceptInvitation} color="success">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Invitations;
